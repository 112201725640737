var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"knowledgeBase"},[_c('div',{staticClass:"title"},[_c('div',{staticClass:"ti-left"},[_vm._v("知识库")]),_c('div',{staticClass:"ti-rigth"},[_c('a-button',{attrs:{"type":"primary","sizi":"small"},on:{"click":_vm.addNewKnowlgeBase}},[_vm._v(" 新建知识库 ")])],1)]),_c('div',{staticClass:"content"},[[_c('a-table',{attrs:{"loading":_vm.loading,"customRow":_vm.customRow,"columns":_vm.columns,"data-source":_vm.knowledgeBaseList,"pagination":false,"scroll":{ y: _vm.fileListEleMaxHeight || 550 }},scopedSlots:_vm._u([{key:"date",fn:function(text, record){return [_c('div',{staticStyle:{"display":"flex","justify-content":"center","width":"100%"}},[(!record?.knowledgeFileDetail?.fileSize)?_c('div',[_c('span',{staticStyle:{"color":"red"}},[_vm._v("暂无文件")])]):_vm._e(),(
                (record?.knowledgeFileDetail?.pendingCount ||
                  record?.knowledgeFileDetail?.pendingCount
                    .inProgressCount) &&
                record?.knowledgeFileDetail?.fileSize &&
                record.knowledgeFileDetail.fileSize !==
                  record.knowledgeFileDetail.completedCount
              )?_c('div',{staticClass:"h-36 lh-9rem"},[_vm._v(" 学习中 "+_vm._s(Math.floor( (record.knowledgeFileDetail.completedCount / record.knowledgeFileDetail.fileSize) * 100 ))+"% "),(record.knowledgeFileDetail.completedCount)?_c('span',{staticStyle:{"margin-left":"6px"}},[_vm._v("成功"+_vm._s(record.knowledgeFileDetail.completedCount)+"个")]):_vm._e(),(record.knowledgeFileDetail.failedCount)?_c('span',{staticStyle:{"margin-left":"6px"}},[_vm._v("失败"+_vm._s(record.knowledgeFileDetail.failedCount)+"个")]):_vm._e(),_c('span',{staticStyle:{"margin-left":"6px"}},[_vm._v("共"+_vm._s(record.knowledgeFileDetail.fileSize)+"个")])]):_vm._e(),(
                !record?.knowledgeFileDetail?.pendingCount &&
                record?.knowledgeFileDetail?.fileSize &&
                record.knowledgeFileDetail.fileSize ==
                  record.knowledgeFileDetail.completedCount +
                    record.knowledgeFileDetail.failedCount
              )?_c('div',[(record.knowledgeFileDetail.failedCount)?_c('span',[_vm._v(" 成功 "+_vm._s(record.knowledgeFileDetail.completedCount)+" 个 ")]):_vm._e(),(record.knowledgeFileDetail.failedCount)?_c('span',{staticStyle:{"margin-left":"4px"}},[_vm._v("失败"+_vm._s(record.knowledgeFileDetail.failedCount)+"个")]):_vm._e(),_c('span',{staticStyle:{"margin-left":"12px"}},[_vm._v(" "+_vm._s(record.knowledgeFileDetail.lastUpdateTime))])]):_vm._e(),(record.knowledgeFileDetail.pendingCount)?_c('div',{staticStyle:{"margin-left":"12px"}},[_c('a-spin')],1):_vm._e()])]}},{key:"action",fn:function(text, record){return [_c('a-dropdown',[_c('a',[_c('a-icon',{attrs:{"type":"dash"}})],1),_c('a-menu',{attrs:{"slot":"overlay"},slot:"overlay"},[_c('a-menu-item',{on:{"click":function($event){return _vm.renameKnowledge(record)}}},[_c('a',[_vm._v("重命名")])]),_c('a-menu-item',{on:{"click":function($event){return _vm.toEditknowledge(record)}}},[_c('a',[_vm._v("编辑知识库")])]),_c('a-menu-item',{on:{"click":function($event){return _vm.delKnowledge(record)}}},[_c('a',[_vm._v("删除")])])],1)],1)]}}])},[_vm._v(" > ")])]],2),(_vm.showConfirmationDialog)?_c('confirmationDialog',{attrs:{"showConfirmationDialog":_vm.showConfirmationDialog,"ModalText":_vm.ModalText,"title":_vm.dialogTitle,"type":_vm.dialogType,"iptValue":_vm.iptValue},on:{"confirmationDialog_Cancel":_vm.confirmationDialog_Cancel,"confirmationDialog_Ok":_vm.confirmationDialog_Ok}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }