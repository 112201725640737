<template>
  <div class="knowledgeBase">
    <div class="title">
      <div class="ti-left">知识库</div>
      <div class="ti-rigth">
        <a-button type="primary" sizi="small" @click="addNewKnowlgeBase">
          新建知识库
        </a-button>
      </div>
    </div>
    <div class="content">
      <!-- 列表 -->
      <template>
        <a-table
          :loading="loading"
          :customRow="customRow"
          :columns="columns"
          :data-source="knowledgeBaseList"
          :pagination="false"
          :scroll="{ y: fileListEleMaxHeight || 550 }"
        >
          >

          <template slot="date" slot-scope="text, record">
            <div style="display: flex; justify-content: center; width: 100%">
              <div v-if="!record?.knowledgeFileDetail?.fileSize">
                <span style="color: red">暂无文件</span>
              </div>

              <div
                v-if="
                  (record?.knowledgeFileDetail?.pendingCount ||
                    record?.knowledgeFileDetail?.pendingCount
                      .inProgressCount) &&
                  record?.knowledgeFileDetail?.fileSize &&
                  record.knowledgeFileDetail.fileSize !==
                    record.knowledgeFileDetail.completedCount
                "
                class="h-36 lh-9rem"
              >
                学习中
                {{
                  Math.floor(
                    (record.knowledgeFileDetail.completedCount /
                      record.knowledgeFileDetail.fileSize) *
                      100
                  )
                }}%

                <span
                  v-if="record.knowledgeFileDetail.completedCount"
                  style="margin-left: 6px"
                  >成功{{ record.knowledgeFileDetail.completedCount }}个</span
                >
                <span
                  v-if="record.knowledgeFileDetail.failedCount"
                  style="margin-left: 6px"
                  >失败{{ record.knowledgeFileDetail.failedCount }}个</span
                >
                <span style="margin-left: 6px"
                  >共{{ record.knowledgeFileDetail.fileSize }}个</span
                >
              </div>

              <div
                v-if="
                  !record?.knowledgeFileDetail?.pendingCount &&
                  record?.knowledgeFileDetail?.fileSize &&
                  record.knowledgeFileDetail.fileSize ==
                    record.knowledgeFileDetail.completedCount +
                      record.knowledgeFileDetail.failedCount
                "
              >
                <span v-if="record.knowledgeFileDetail.failedCount">
                  成功
                  {{ record.knowledgeFileDetail.completedCount }} 个
                </span>
                <span
                  v-if="record.knowledgeFileDetail.failedCount"
                  style="margin-left: 4px"
                  >失败{{ record.knowledgeFileDetail.failedCount }}个</span
                >
                <span style="margin-left: 12px">
                  {{ record.knowledgeFileDetail.lastUpdateTime }}</span
                >
              </div>

              <div
                v-if="record.knowledgeFileDetail.pendingCount"
                style="margin-left: 12px"
              >
                <a-spin />
              </div>
            </div>
          </template>

          <template slot="action" slot-scope="text, record">
            <a-dropdown>
              <a><a-icon type="dash" /></a>
              <a-menu slot="overlay">
                <a-menu-item @click="renameKnowledge(record)">
                  <a>重命名</a>
                </a-menu-item>
                <a-menu-item @click="toEditknowledge(record)">
                  <a>编辑知识库</a>
                </a-menu-item>
                <a-menu-item @click="delKnowledge(record)">
                  <a>删除</a>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </template>
        </a-table>
      </template>
    </div>

    <!-- 确认切换和关闭弹窗 -->
    <confirmationDialog
      v-if="showConfirmationDialog"
      :showConfirmationDialog="showConfirmationDialog"
      :ModalText="ModalText"
      :title="dialogTitle"
      :type="dialogType"
      :iptValue="iptValue"
      @confirmationDialog_Cancel="confirmationDialog_Cancel"
      @confirmationDialog_Ok="confirmationDialog_Ok"
    />
  </div>
</template>

<script>
import { publicFormatTime } from "@/utils/publicFormat";
import confirmationDialog from "@/components/confirmationDialog/index";
export default {
  name: "knowledgeBase",
  components: {
    confirmationDialog,
  },

  data() {
    return {
      pollInterval: null,
      id: "", // 主键id
      typeFn: "",
      iptValue: "",
      knowledgeId: "",
      dialogType: "createOrRename",
      dialogTitle: "新建知识库",
      showConfirmationDialog: false,
      ModalText: "",
      fileListEleMaxHeight: 550,
      name: "知识库",
      pageNum: 1,
      loading: false,
      listData: [],
      columns: [
        {
          // align: "center",
          title: "名称",
          dataIndex: "knowledgeName",
          key: "knowledgeName",
        },
        {
          align: "center",
          title: "最近学习时间",
          scopedSlots: { customRender: "date" },
          key: "id",
        },
        // {
        //   title: "Tags",
        //   key: "tags",
        //   dataIndex: "tags",
        //   scopedSlots: { customRender: "tags" },
        // },
        {
          align: "center",
          width: 380,
          title: "操作",
          scopedSlots: { customRender: "action" },
        },
      ],
    };
  },
  computed: {
    knowledgeBaseList() {
      return this.$store.state.knowledgeBase.knowledgeBaseList;
    },
  },

  created() {
    this.getKnowlgeBaseList();
  },
  mounted() {
    window.addEventListener("resize", this.updateBrowserHeight);
    this.$nextTick(() => {
      setTimeout(() => {
        this.computedHeightFn();
      }, 500);
    });
  },
  beforeDestroy() {
    if (this.pollInterval) {
      clearInterval(this.pollInterval);
      this.pollInterval = null;
    }

    window.removeEventListener("resize", this.updateBrowserHeight);
  },
  methods: {
    // 计算高度
    computedHeightFn() {
      const star_content_fileContainer =
        document.querySelector(".knowledgeBase");
      // const star_header_top = document.querySelector(".star-header-top");
      const star_content_fileContainer_height =
        star_content_fileContainer && star_content_fileContainer.offsetHeight;

      // const star_header_top_height =
      //   star_header_top && star_header_top.offsetHeight;

      this.fileListEleMaxHeight =
        // star_content_fileContainer_height - star_header_top_height - 20;
        star_content_fileContainer_height - 150;
      console.log("知识库列表滚动的最大高度是多少", this.fileListEleMaxHeight);
    },

    // 用户改变了浏览器高度
    updateBrowserHeight() {
      this.browserHeight = window.innerHeight;
      this.$nextTick(() => {
        this.computedHeightFn();
      });
    },

    // 重命名知识库
    renameKnowledge(row) {
      console.log("重命名知识库", row);
      this.id = row.id;
      this.dialogTitle = "重命名知识库";
      this.dialogType = "createOrRename";
      this.typeFn = "renameKnowledge";
      this.iptValue = row.knowledgeName;
      this.showConfirmationDialog = true;
    },

    // 删除知识库
    delKnowledge(row) {
      console.log("删除知识库", row);
      this.knowledgeId = row.knowledgeId;
      this.typeFn = "delKnowledge";
      this.dialogType = "del";
      this.dialogTitle = "删除知识库";
      this.ModalText = `确认删除知识库【${row.knowledgeName}】吗? 一旦操作不可撤回，请谨慎操作`;
      this.showConfirmationDialog = true;
    },

    // 关闭弹窗
    confirmationDialog_Cancel() {
      this.showConfirmationDialog = false;
    },
    // 点击弹窗确认
    confirmationDialog_Ok(val) {
      console.log("新建知识库的名称", val);

      this.confirmationDialog_Cancel();

      if (this.typeFn == "addNewKnowlgeBase") {
        this.addKnowledgeFn(val);
      } else if (this.typeFn == "delKnowledge") {
        this.delKnowledgeFn(val);
      } else if (this.typeFn == "renameKnowledge") {
        this.renameKnowledgeFn(val);
      }
    },

    async renameKnowledgeFn(newValue) {
      const params = {
        // id: props.knowledgeBaseRowInfo.knowledgeId,
        id: this.id,
        knowledgeName: newValue,
      };
      console.log("--确认重命名知识库--params", params);
      try {
        const res = await this.$apis.renameKnowledgeHttp(params);
        console.log("--确认重命名知识库--res", res);
        this.$message.success("修改成功");
        this.getKnowlgeBaseList();
      } catch (error) {
        console.log("--确认重命名知识库--error", error);
      } finally {
      }
    },

    async addKnowledgeFn(newVal) {
      this.loading = true;
      const params = {
        knowledgeName: newVal,
      };
      try {
        const res = await this.$apis.addKnowledgeHttp(params);
        this.$message.success("新增成功");
        // store.dispatch("knowledgeBase/async_setKnowledgeBaseList_ByHttp");
        this.getKnowlgeBaseList();
      } catch (error) {
        console.log("新增知识库error", error);
      }
    },

    async delKnowledgeFn() {
      const params = {
        knowledgeId: this.knowledgeId,
      };
      try {
        const res = await this.$apis.delKnowledgeListHttp(params);
        this.$message.success("删除成功");
        this.getKnowlgeBaseList();
      } catch (error) {
        console.log("删除知识库-error", error);
      } finally {
      }
    },

    customRow(record) {
      return {
        on: {
          // 事件
          click: () => {
            console.log("123");
          }, // 点击行
          dblclick: () => {
            console.log("双击", record);
            this.$router.push({
              name: "knowledgeQuestionsAndAnswers",
              query: record,
            });
          },
        },
      };
    },
    // 编辑器知识库
    toEditknowledge(record) {
      this.$router.push({
        name: "editKnowledge",
        query: record,
      });
      console.log("编辑=================知识库");
      // router.push({
      //   name: "editKnowledge",
      //   query: props.knowledgeBaseRowInfo,
      // });
    },
    addNewKnowlgeBase() {
      console.log("新建知识库");
      this.dialogTitle = "新建知识库";
      this.dialogType = "createOrRename";
      this.iptValue = "";
      this.ModalText = "";
      this.typeFn = "addNewKnowlgeBase";
      this.showConfirmationDialog = true;
    },
    // 检查是否需要轮询
    checkPollingCondition() {
      let allComplete = true;
      for (const item of this.listData) {
        const { completedCount, failedCount, fileSize } =
          item.knowledgeFileDetail;
        const inProgress = completedCount + failedCount < fileSize;

        if (inProgress) {
          allComplete = false;
          break;
        }
      }

      if (allComplete) {
        // 如果所有知识库都已完成，停止轮询
        console.log("知识库列表---停止轮训");
        clearInterval(this.pollInterval);
        this.pollInterval = null;
      } else {
        if (!this.pollInterval) {
          // 开始轮询，每隔5秒调用一次
          this.pollInterval = setInterval(() => {
            this.getKnowlgeBaseList(true);
          }, 5000);
        }
      }
    },
    async getKnowlgeBaseList(flag) {
      if (!flag) {
        this.loading = true;
      }
      const params = {
        isPage: 1,
        pageNum: this.pageNum,
        pageSize: 999,
      };
      try {
        const res = await this.$apis.getKnowledgeUserListHttp(params);
        if (res.code == 200) {
          this.listData = res.data.list;
          this.listData?.length &&
            this.listData.forEach((item) => {
              if (
                item.knowledgeFileDetail &&
                item.knowledgeFileDetail.lastUpdateTime
              ) {
                item.knowledgeFileDetail.lastUpdateTime = publicFormatTime(
                  item.knowledgeFileDetail.lastUpdateTime
                );
              }
            });

          this.$store.commit(
            "knowledgeBase/setKnowledgeBaseList",
            this.listData
          );
        }
      } catch (error) {
        console.log("请求知识库列表error", error);
      } finally {
        this.loading = false;
        this.checkPollingCondition();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.knowledgeBase {
  width: 100%;
  height: 100%;
  padding: 20px;
  padding-top: 0px !important;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  .title {
    width: 100%;
    display: flex;
    font-weight: 600;
    height: 36px;
    font-size: 18px;
    align-items: center;
    justify-content: space-between;
  }
  .content {
    user-select: none;
    padding: 20px;
    padding-right: 40px !important;
  }
}
</style>
